import "./Button.scss";
import React from "react";
import { Link } from "react-router-dom";

export default function Button({
  type,
  text,
  theme,
  url,
  classNames,
  onClick,
  filePath
}) {
  const applyTheme = theme ? theme : "";
  const applyClassNames = classNames ? classNames.join(" ") : "";

  const downloadFile = filePath => {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  };

  const renderButton = () => {
    switch (type) {
      case "redirect":
        return (
          <div className={`button ${applyTheme} ${applyClassNames}`}>
            <Link to={url || "/"}>
              <div className="buttonSlide"></div>
              <button onClick={onClick}>{text}</button>
            </Link>
          </div>
        );

      case "submit":
        return (
          <div className={`button ${applyTheme} ${applyClassNames}`}>
            <div className="buttonSlide"></div>
            <button type={type}>{text}</button>
          </div>
        );

      case "load-more":
        return (
          <div className={`button centered ${applyTheme} ${applyClassNames}`}>
            <div className="buttonSlide"></div>
            <button onClick={onClick}>{text}</button>
          </div>
        );

      case "download":
        return (
          <div className={`button centered ${applyTheme} ${applyClassNames}`}>
            <div className="buttonSlide"></div>
            <button onClick={file => downloadFile(filePath)}>{text}</button>
          </div>
        );

      case "_blank":
        return (
          <div className={`button ${applyTheme} ${applyClassNames}`}>
            <a
              rel="nofollow noopener noreferrer"
              href={url || "/"}
              target="_blank"
            >
              <div className="buttonSlide"></div>
              <button onClick={onClick}>{text}</button>
            </a>
          </div>
        );


      default:
        return (
          <div className={`button ${applyTheme} ${applyClassNames}`}>
            <div className="buttonSlide"></div>
            <button type="button" onClick={onClick}>
              {text}
            </button>
          </div>
        );
    }
  };

  return renderButton();
}
