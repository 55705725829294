import { FETCH_SOCIAL_FEED, UPDATE_SOCIAL_FEED } from "../actions/types";

const INITIAL_STATE = {
  data: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SOCIAL_FEED:
      return action.payload;

    case UPDATE_SOCIAL_FEED:
      const newState = {...state}
      const newArticles = [...state.articles, ...action.payload.articles]
      newState.articles = newArticles;
      newState.pagination = action.payload.pagination;
      return newState;
      
    default:
      return state;
  }
};
