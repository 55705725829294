import React from "react";
import { FaAngleDown } from "react-icons/fa";

function ScrollIntoView(props) {
  const style = {
    cursor: 'pointer',
    fontSize: '35px',
    position: 'absolute',
    top: '95%',
    left: '50%',
    transform:' translate(-50%,-50%)',
    textAlign: 'center',
    color: '#43358E',
 
  }
  return (
    <div style={style} onClick={props.handleScrollDown}>
      <FaAngleDown />
    </div>
  );
}

export default ScrollIntoView;
