import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import FormRegister from "../../forms/register/FormRegister";
import {Link} from 'react-router-dom'

const Register = () => {
  return (
    <div className="services">
      <BannerHeader
        image="/assets/image/banners-optimized/log-in-banner@2x-compressor.jpg"
        title="Registration Page"
      >
        <p className="white breadcrumb">
          <Link to="/">Home</Link> | <Link to="/register">Register</Link>
        </p>
      </BannerHeader>
      <FormRegister />
    </div>
  );
};

export default Register;
