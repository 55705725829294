import _ from 'lodash'
import { FETCH_NEWS_ARTICLES, FETCH_NEWS_ARTICLE } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_NEWS_ARTICLE:
      return { ...state, [action.slug]: action.payload };
    case FETCH_NEWS_ARTICLES:
      return {...state, ..._.mapKeys(action.payload, 'slug')};
    default:
      return state
  }
}
