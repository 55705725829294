import "./FooterNavSecond.scss";
import React from "react";
import { Link } from "react-router-dom";

function FooterNavSecond() {

  const date = new Date();
  return (
    <React.Fragment>
      <nav className="footer-nav-second">
        <ul>
          {/* <li>
            <Link to="/cookie-policy">Cookie Policy</Link>
          </li> */}
          <li>
            {/*<Link to="/privacy-policy">Privacy Policy</Link>*/}
            <a href="/assets/pdf/Privacy-Policy-2023.pdf" target="_blank">Privacy Policy</a>
          </li>
          <li>
            <a href="/assets/pdf/PAIA-Manual-Accountability-2023.pdf" target="_blank">PAIA Manual</a>
          </li>
          <li>
            <a href="/assets/pdf/ACC-ISP-024-Personal-Information-Protection-Policy-2.pdf" target="_blank">Personal Information Protection Policy</a>
          </li>
          <li>All rights reserved</li>
          <li>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </li>
          <li>
            <Link to="/disclaimer">Disclaimer</Link>
          </li>
        </ul>
      </nav>
      <nav className="footer-nav-second">
      <ul>
        <li>Copyrights © {date.getFullYear()} Accountability</li>
        <li>
          Powered by &nbsp;
          <a
            rel="nofollow noopener noreferrer"
            href="https://optimalonline.co.za"
            target="_blank"
          >
            <span className="bold">Optimal Online</span>
          </a>
        </li>
      </ul>
    </nav>
  </React.Fragment>
  );
}

export default FooterNavSecond;
