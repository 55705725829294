
import React from 'react'

export default function NavbarItem({ children, closeMenu }) {
  return (
    <li onClick={closeMenu} className="nav-item">
      {children}
    </li>
  )
}
