import { allLetter } from "../../../utils/functions/index";

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const validate = values => {
  const errors = {};

  // COMPANY INFORMATION

  if (!values.public_3in1_registration) {
    errors.public_3in1_registration = "* Required";
  }

  if (!values.public_3in1_query_type) {
    errors.public_3in1_query_type = "* Required";
  }

  if (!values.public_3in1_reason) {
    errors.public_3in1_reason = "* Required";
  }

  if (!values.public_3in1_name) {
    errors.public_3in1_name = "* Required";
  }

  if (values.public_3in1_name) {
    let s = allLetter(values.public_3in1_name);
    if (!s) {
      errors.public_3in1_name = "* Please include only letters.";
    }
  }

  if (!values.public_3in1_email) {
    errors.public_3in1_email = "* Required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.public_3in1_email)
  ) {
    errors.public_3in1_email = "* Invalid email address";
  }

  if (!values.email_address_confirmation) {
    errors.email_address_confirmation = "* Required";
  }

  if (
    values.email_address_confirmation &&
    values.email_address_confirmation !== values.public_3in1_email
  ) {
    errors.email_address_confirmation = "Email and Confirm do not match.";
  }

  if (!values.agree_terms) {
    errors.agree_terms = "* Required";
  }

  if (!values.public_3in1_answer_1) {
    errors.public_3in1_answer_1 = "* Required";
  }
  if (!values.public_3in1_answer_2) {
    errors.public_3in1_answer_2 = "* Required";
  }
  if (!values.public_3in1_answer_3) {
    errors.public_3in1_answer_3 = "* Required";
  }
  if (!values.public_3in1_answer_4) {
    errors.public_3in1_answer_4 = "* Required";
  }
  if (!values.public_3in1_answer_5) {
    errors.public_3in1_answer_5 = "* Required";
  }

  return errors;
};

export default validate;
