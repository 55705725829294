import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import {
  buildPayfastQuery,
  fetchLocalStorage
} from "../../../utils/functions/index";
import { createRegisterPayment } from "../../../actions/index";

import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import validate from "./validate";
import Text from "../../ui/text/Text";
import { v4 as uuid } from 'uuid';

class RegisterPayfast extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const userTokens = fetchLocalStorage("userTokens");
    if (userTokens.registration_token) {
      this.props.createRegisterPayment(userTokens.registration_token);
    }
  }

  onSubmit = formValues => {
    const userPaymentTokens = fetchLocalStorage("userPaymentTokens");

    const tokens = {
      type: "register",
      payment_id: uuid(),
      ...fetchLocalStorage("userTokens"),
      registration_payment_token: userPaymentTokens.registration_payment_token
    };

    const queryURL = buildPayfastQuery({
      itemName: "Accountability Registration " + userPaymentTokens.registration_reference_number,
      amount: 366.00,
      userDetails: fetchLocalStorage("userDetails"),
      returnURL: "/register-complete",
      cancelURL: "/register-cancel",
      tokens
    });

    window.location.href = queryURL;
  };

  renderPayfastForm = () => {
    const { handleSubmit } = this.props;

    return (
      <form
        onSubmit={handleSubmit(this.onSubmit)}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        <img src="../../../assets/image/payfast-logo.svg" alt="payfast-logo" />
        <div className="spacer"></div>
        <Text
          type="p"
          className="centered medium p-t-60"
          text="This is the PayFast process. When this form is completed and payment has been made you will be redirected to a thank you page."
        />
        <CenteredBox>
          <Button type="submit" text="NEXT" theme="green" />
        </CenteredBox>
      </form>
    );
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          bottom: "0",
          left: "0",
          zIndex: 999,
          background: "#fff"
        }}
      >
        {this.renderPayfastForm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    register: state.register
  };
};

const formWrapped = reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(RegisterPayfast);

export default connect(mapStateToProps, { createRegisterPayment })(formWrapped);
