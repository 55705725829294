import "./CookiePolicy.scss";
import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import Section from "../../ui/section/Section";
import Container from "../../ui/container/Container";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
// import ButtonList from "../../ui/button/ButtonList";
// import Button from "../../ui/button/Button";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

function CookiePolicy() {
  return (
    <div className="terms-and-conditions">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cookie Policy | Accountability Group Pty Ltd</title>
        <meta
          name="description"
          content="Strictly necessary cookies. These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website or make use of online registration/payment services."
        />
        <link rel="canonical" href={window.location} />
      </Helmet>

      <BannerHeader
        image="/assets/image/banners-optimized/services-banner@2x-compressor.jpg"
        title="Cookie Policy"
        // breadcrumb="Home | Cookie Policy"
      >
        <p className="white breadcrumb">
          <Link to="/">Home</Link> | <Link to="/cookie-policy">Cookie Policy</Link>
        </p>
      </BannerHeader>
      <Section>
        <Container>
          <Grid>
            <GridItem classNames={["col-md-12"]}>
              
              <p>This Cookie Policy was last updated on 12 March 2020</p>

              <h2 className="fontPurple">General</h2>

              <p>www.accountability.co.za is a website operated by Accountability Group (Pty) Ltd and Accountability Solutions (Pty) Ltd (collectively referred herein as ‘Accountability’) registered in the Republic of South Africa under company registration numbers 2008/012163/07 and 2017/365254/07 respectively, at postal address PO Box 1078 Brackenfell, 7560</p>

              <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with the best possible experience when browsing our website and also allows us to improve our website. By continuing to browse the website, you are agreeing to our use of cookies.</p>

              <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.</p>

              <h2 className="fontPurple">We use the following cookies:</h2>

              <ul className="terms-list">

                <li>
                  <span>1.</span>
                  <p><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website or make use of online registration/payment services.</p>
                </li>

                <li>
                  <span>2.</span>
                  <p><strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</p>
                </li>

                <li>
                  <span>3.</span>
                  <p><strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences.</p>
                </li>

                <li>
                  <span>4.</span>
                  <p><strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p>
                </li>

                <li>
                  <span>5.</span>
                  <p><strong>Estimate</strong> our audience size and usage pattern.</p>
                </li>
                
                <li>
                  <span>6.</span>
                  <p><strong>Store information</strong> about your preferences, and so allow us to customise our site and to provide you with offers that are targeted at your individual interests.</p>
                </li>

                <li>
                  <span>7.</span>
                  <p><strong>Speed</strong> up your searches.</p>
                </li>

                <li>
                  <span>8.</span>
                  <p><strong>Recognise</strong> you when you return to our site.</p>
                </li>

                <li>
                  <span>9.</span>
                  <p>Allow you to use our site in a way that makes your browsing experience more convenient, for example, by allowing you to store items in an electronic shopping basket between visits. If you register with us or complete our online forms, we will use cookies to remember your details during your current visit, and any future visits provided the cookie was not deleted in the interim.</p>
                </li>
                
              </ul>

              <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>

              <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site. </p>

              <h2 className="fontPurple">Contact Us</h2>

              <p>If you have any questions about this Cookie policy or how Accountability processes your personal information, please contact us by email to disputes@accountability.co.za or by postal mail to <br />
              PO Box 1078 <br />
              Brackenfell, <br />
              7560
              </p>
            </GridItem>
          </Grid>

          
        </Container>
      </Section>
    </div>
  );
}

export default CookiePolicy;
