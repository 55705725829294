import "./Footer.scss";
import React from "react";
import Container from "../container/Container";
import Section from "../section/Section";
import Text from "../text/Text";
import Logo from "../logo/Logo";
import FooterNav from "./FooterNav";
import FooterNavSecond from "./FooterNavSecond";
import FooterSocialNav from "./FooterSocialNav";
import IconList from "../icon/icon-list/IconList";
import Icon from "../icon/icon/Icon";
import Covid19 from '../disclaimer/Covid19'

function Footer() {
  return (
    <footer>
      <Section id="section-footer-nav" noMargin>
        <Container>
          <Logo position="centered" type="light" />
          <FooterNav />
          <FooterSocialNav />
          <FooterNavSecond />
        </Container>
      </Section>

      <Section id="associations">
        <Container>
          <Text
            type="p"
            className="centered small"
            text="ADVISORY: Users are advised that, by clicking on any “submit” button on this site, the user thereby submits an electronic signature, as contemplated in Section 11 of the Electronic Communications and Transactions Act, No.25 of 2002. Such signature carries with it all the weight of all contractual and legal obligations which may arise from the submission of such signature."
          />
          <IconList centered willStack>
            <Icon classNames={[]}>
              <img
                src="/assets/image/footer_logos/ISO_IEC-27001-Information-Security.jpg"
                alt=""
              />
            </Icon>

            <Icon classNames={[]}>
              <img
                src="/assets/image/footer_logos/Quality-9001_2015-Logo.jpg"
                alt=""
              />
            </Icon>

            <Icon classNames={[]}>
              <img
                src="/assets/image/footer_logos/geotrust-logo@2x.png"
                alt=""
              />
            </Icon>

            <Icon classNames={[]}>
              <img src="/assets/image/footer_logos/ncr-logo@2x.png" alt="" />
            </Icon>

            <Icon classNames={[]}>
              <img src="/assets/image/footer_logos/cdc-logo@2x.png" alt="" />
            </Icon>
          </IconList>
          <Text
            type="p"
            className="centered small"
            text={`DISCLAIMER: Accountability Group (Pty) Ltd and Accountability Solutions (Pty) Ltd act as a duly authorised agents on behalf and under instruction of its members. The information in this message is confidential and may be legally privileged. It is intended solely for the addressee. Access to this message by anyone else is unauthorised. If you are not the intended recipient, any disclosure, copying, or distribution of the message, or any action or omission taken by you in reliance on it, is prohibited and may be unlawful. Please immediately contact the sender if you have received this message in error. Recipients acknowledge that any information obtained from us relating to what is defined as "personal information" in terms of the Protection of Personal Information Act No 4 of 2013 (POPI), is to be treated in a manner commensurate with the provisions of that Act. Such information may not be distributed, published or used by recipients in any manner prohibited by POPI`}
          />

          <Text
            type="p"
            className="centered small bold"
            text={`Accountability Group (Pty) Ltd is a registered Credit Bureau in terms of Section 43 of the National Credit Act 34 of 2005. <br>
            Registration No: 2008/012163/07 | NCR No: NCRCB33 `}
          />

          <br />
          <Text
            type="p"
            className="centered small bold"
            text="Accountability Solutions (Pty) Ltd is an independent entity that specialises in the recoveries of outstanding debt. <br> Registration No: 2017/365254/07 | Council for Debt Collectors Registration No: 0117675/19"
          />
        </Container>
      </Section>
      <Covid19 />
    </footer>
  );
}

export default Footer;
