import React from 'react'
import ScrollIntoView from "../../behaviour/ScrollIntoView";

export default function SliderItem({ children, image, autoHeight, scrollDown, mobile }) {
  let styles = {
    background: `url('${image}')`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    width: '100%',
    position: 'relative',
   
  }

  // if (window.innerWidth < 1024 && mobile) {
    const mobileStyles = {
      background: `url('${mobile}')`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      width: '100%',
      position: 'relative',
     
    }
  // }

  if (!autoHeight) {
    styles.height = 'calc(100vh - 253px)';
  }

  if (window.innerWidth < 1024 && mobile) {
    mobileStyles.height = 'calc(100vh - 138px)';
    styles = mobileStyles;
  } else {
    styles.height = 'calc(100vh - 138px)';
  }

  return (
    <div className="slider-item" style={styles}>
      {children}
      {scrollDown && <ScrollIntoView handleScrollDown={scrollDown}/>}
    </div>
  )
}
 