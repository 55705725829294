import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import accountability from "../../../api/accountability";
import {
  verifyBankAccount,
  fetchAccountTypes,
  fetchInstitutions,
  throwError,
  isLoading
} from "../../../actions/index";
import validate from "./validate";
import {
  renderInput,
  renderHiddenInput,
  renderSelect,
  renderMultiSelect,
  FieldFileInput,
  // renderCheckbox
} from "../renderField";
import Button from "../../ui/button/Button";
import ButtonList from "../../ui/button/ButtonList";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import FormContainer from "../../ui/container/form-container/FormContainer";
import Container from "../../ui/container/Container";
import Text from "../../ui/text/Text";
import { SubmissionError } from "redux-form"; 
import Loader from "../../ui/loader/Loader";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class RegisterFormSecondPage extends Component {
  constructor(props) {
    super(props);

    this.upload = React.createRef();

    this.state = {
      trimmedDataURL: "",
      branchCode: "Branch Code"
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchAccountTypes();
    this.props.fetchInstitutions();
    this.props.change("is_valid", this.props.isValid);
  }

  onInstitutionSelect = e => {
    for (var property in this.props.institutions) {
      if (this.props.institutions[property] === e.target.value) {
        this.props.changeInstitution(property);
        this.props.change("company_bank_branch_code", property);
      }
    }
  };

  createInstitutionOptions = () => {
    let institutionOptions = [];

    if (this.props.institutions)
      for (let property in this.props.institutions) {
        if (
          this.props.institutions.hasOwnProperty(property)
        ) {
          let item = {
            key: property,
            val: this.props.institutions[property]
          };
          institutionOptions.push(item);
        }
      }

    institutionOptions
      .sort((a, b) => (a.val > b.val ? 1 : -1))
      .unshift({
        key: null,
        val: "Please select"
      });

    return institutionOptions;
  };

  createAccountTypeOptions = () => {
    let accountTypeOptions = this.props.accountTypes
      ? this.props.accountTypes.map(type => {
          return type;
        })
      : [];

    accountTypeOptions
      .sort((a, b) => (a > b ? 1 : -1))
      .unshift("Please select");

    return accountTypeOptions;
  };

  //displays the upload below the input field and resets the drawing pad
  handleImageUpload = async data => {
    await this.setState({ upload: data });
    this.props.change("copy_of_bank_letter", data);
    // this.upload.current.src = URL.createObjectURL(data);
  };

  //base64 encode the file for uploading
  async handleImageChange(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.setState({ upload: reader.result });
        resolve(resolve);
      };
    });
  }

  onSubmit = async formValues => {
    this.props.isLoading(true);

    const data = {
      account_number: formValues.company_bank_account_no,
      branch_code: formValues.company_bank_branch_code,
      account_type: formValues.company_bank_account_type
    };

    let upload = formValues.copy_of_bank_letter

    if(upload){
      if (upload.type !== undefined && upload.type === "application/pdf") {
        await this.handleImageChange(upload);
        console.log(
          "this.state.upload",
          this.state.upload
        );
        formValues.copy_of_bank_letter = this.state.upload;
      }
    }

    try {
      const response = await accountability.post(
        "/index.php?c=rest/public/v1/verify_bank_account",
        data
      );

      if (response.data.data.error) {
        window.scrollTo(0, 0);
        this.props.isLoading(false);
        throw new SubmissionError({
          company_bank_account_no: "* Please enter a valid account number",
          _error: "* Please enter a valid account number"
        });
      }

      this.props.nextPage();
    } catch (error) {
      window.scrollTo(0, 0);
      this.props.isLoading(false);
      throw new SubmissionError({
        company_bank_account_no: "* Please enter a valid account number",
        _error: "* Please enter a valid account number"
      });
    }

    this.props.isLoading(false);
  };

  render() {
    const { handleSubmit, previousPage } = this.props;

    return (
      <React.Fragment>
        <Section>
          <SectionHeader autoWidth title="Banking Details" noPadding />

          <form
            onSubmit={handleSubmit(this.onSubmit)}
            id="form-banking-details"
          >
            <FormContainer>
              {'company_bank_account_name' && <div name={`position-company_bank_account_name`} />}
              <Field
                name="company_bank_account_name"
                type="text"
                component={renderInput}
                label="Account in name of"
              />
              {'company_bank_account_no' && <div name={`position-company_bank_account_no`} />}
              <Field
                name="company_bank_account_no"
                type="number"
                component={renderInput}
                label="Account Number (no spaces)"
              />
              {'company_bank_name' && <div name={`position-company_bank_name`} />}
              <Field
                onChange={event => {
                  this.onInstitutionSelect(event);
                }}
                name="company_bank_name"
                type="text"
                options={this.createInstitutionOptions()}
                component={renderMultiSelect}
                label="Institution"
              />
              {'company_bank_account_type' && <div name={`position-company_bank_account_type`} />}
              <Field
                name="company_bank_account_type"
                type="text"
                options={this.createAccountTypeOptions()}
                component={renderSelect}
                label="Account Type"
              />
              {'company_bank_branch_name' && <div name={`position-company_bank_branch_name`} />}
              <Field
                name="company_bank_branch_name"
                type="text"
                component={renderInput}
                label="Branch Name"
              />
              {'company_bank_branch_code' && <div name={`position-company_bank_branch_code`} />}
              <Field
                name="company_bank_branch_code"
                type="text"
                component={renderInput}
                label="Branch Code"
              />
              
              <Field
                btnClass="copy-of-bank-letter"
                accept=".jpg, .png, .jpeg"
                onChange={this.handleImageUpload}
                name="copy_of_bank_letter"
                inputName="copy_of_bank_letter"
                type="file"
                component={FieldFileInput}
                label="Please upload a copy of the bank letter"
              />

              {this.state.upload && (
                <>
                  <br />
                  <p>Document uploaded &#10003;</p>
                </>
              )}

              <Field
                val={this.props.isValid}
                name="is_valid"
                type="hidden"
                component={renderHiddenInput}
              />
            </FormContainer>

            <div className="spacer-90"></div>

            <SectionHeader
              autoWidth
              title="Important Information:"
              noPadding
              leftAligned
            />

            <Container>
              <article>
                <Text
                  type="h3"
                  className="default p-t-0"
                  text="Activation will take place upon receipt of:"
                />
                <ul className="form-list m-b-20">
                  <li>1. Activation fee of R366.00 (incl. VAT) </li>
                  <li>2. Signed documents</li>
                </ul>

                <Text type="h3" className="default" text="Please Note:" />
                <Text
                  type="p"
                  text=" Accountability will be verifying your entity’s details by requesting either a Business* or Consumer Credit Report which will be available for 
              you to view under “Report History”. The cost of the report will be for your account."
                />

                <Text
                  type="p"
                  text="Upon activation, your Username and Password will be 
              sent to your nominated email address."
                />

                <Text
                  type="p"
                  text="The Accountability Membership contract is a 12 month contract at R366.00 (incl. VAT) per month excluding 
              your monthly instruction activity on our site. Should you wish to cancel, you should do so a month prior to your anniversary date. Failure to 
              do so will result in the renewal of your contract for a further 12 months."
                />

                <Text
                  type="p"
                  text="Invoices for the monthly premium and services rendered will
               be collected by debit order, no earlier than the 25th of the month and by no later than the last working day of that month. 
              This will also apply to collections during December."
                />

                <Text
                  type="p"
                  text="*Should you be joining as a Sole Proprietor, a Consumer credit report will be done."
                />
              </article>

              {this.props.loading ? (
                <Loader />
              ) : (
                <ButtonList>
                  <Button
                    type="button"
                    onClick={previousPage}
                    classNames={["previous", "centered"]}
                    theme="green"
                    text="PREVIOUS"
                  >
                    PREVIOUS
                  </Button>

                  <Button
                    type="submit"
                    classNames={["next", "centered"]}
                    theme="green"
                    text="NEXT"
                  >
                    Next
                  </Button>
                </ButtonList>
              )}
            </Container>
          </form>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountTypes: Object.values(state.accountTypes),
    institutions: state.institutions,
    loading: state.isLoading
  };
};

const formWrapped = reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
  // asyncValidate
})(RegisterFormSecondPage);

export default connect(mapStateToProps, {
  verifyBankAccount,
  fetchAccountTypes,
  fetchInstitutions,
  throwError,
  isLoading
})(formWrapped);