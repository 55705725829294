import React, { Component } from "react";
import FormReferalFirstPage from "./FormReferalFirstPage";

class FormReferal extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
      isValid: false
    };
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { page } = this.state;

    return (
      <React.Fragment>
        {page === 1 && <FormReferalFirstPage onSubmit={this.nextPage} />}
      </React.Fragment>
    );
  }
}

export default FormReferal;
