import "./PreviewCard.scss";
import React from "react";
import Text from "../../text/Text";
import { textTruncate } from "../../../../utils/functions";
import Button from "../../button/Button";
import { Link } from "react-router-dom";
export default function PreviewCard({ title, text, image, truncated, url }) {
  // const renderButton = () => {
  //   return url && <Button type="redirect" url={url} theme="naked" text="Read More"/>
  // }

  const renderImage = () => {
    return (
      image && (
        <div className="preview-image">
        
          <img src={image} alt={title} />
        </div>
      )
    );
  };

  const renderText = () => {
    return truncated ? textTruncate(text, 80, "...") : text;
  };

  return (
    <div className="preview-card">
      <Link to={url}>{renderImage()}</Link>

      <div className="preview-card-content">
        <Link to={url}>
          <Text type="h3" text={title} className="underlined" />
          <Text type="p" text={renderText()} />
        </Link>
        <Button
          type="redirect"
          text="Read More"
          theme="naked"
          url={url}
          classNames={["no-hover"]}
        />
      </div>
    </div>
  );
}
