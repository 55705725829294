export default function HideOnMobile({ children }) {

  const renderComponent = () => {
    if( window.innerWidth > 830) {
      return children
    }

    return null
  }
  
  return renderComponent();
}
