import "./Modal.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../../actions/index";

class Modal extends Component {
  close = () => {
    this.props.hideModal(this.props.id);
  };

  render() {
    const renderModal = () => {
      return (
        <React.Fragment>
          <div onClick={this.close} className="close">
            <img src="/assets/image/close.svg" alt="" />
          </div>
          {this.props.children}
        </React.Fragment>
      );
    };

    return renderModal();
  }
}

export default connect(
  null,
  { hideModal }
)(Modal);
