import "./Form.scss";
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { renderInput, renderCheckbox } from "./renderField";
import { connect } from "react-redux";
import { sendMail } from "../../actions/index";
import FormContainer from "../ui/container/form-container/FormContainer";
import Button from "../ui/button/Button";
import { allLetter } from "../../utils/functions/index";
import scrollToFirstError from './SmoothScrollToErrorFields.js';

class FormContact extends Component {

  state = {
    submit: false
  }

  onSubmit = formValues => {
    formValues.subject = this.props.subject;

    this.props.sendMail(formValues);
    this.setState({submit: true})
  };

  render() {
    return (
      <FormContainer noPadding>
        <form
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          className="ui form error"
        >
          {'full_name' && <div name={`position-full_name`} />}
          <Field
            name="full_name"
            component={renderInput}
            label="Name & Surname:"
          />
          {'company_name' && <div name={`position-company_name`} />}
          <Field
            name="company_name"
            component={renderInput}
            label="Company Name:"
          />
          {'phone' && <div name={`position-phone`} />}
          <Field
            name="phone"
            type="number"
            component={renderInput}
            label="Contact Number:"
          />
          {'email' && <div name={`position-email`} />}
          <Field
            name="email"
            component={renderInput}
            label="Email Address:"
            type="email"
          />
          {'agree_contact' && <div name={`position-agree_contact`} />}
          <Field
            name="agree_contact"
            type="checkbox"
            component={renderCheckbox}
            label="I hereby provide consent to be contacted by an Accountability representative."
            noMargin
          />

          <div className="form-footer centered" style={{marginTop: '30px'}}>
          { !this.state.submit &&
                (
                  <Button type="submit" theme="green" text="SUBMIT" />
                )
              }
              { this.state.submit &&
                (
                  <Button type="button" theme="green" text="SUBMITTING" />
                )
              }
            
          </div>
        </form>
      </FormContainer>
    );
  }
}

const validate = formValues => {
  const errors = {};

  if (!formValues.agree_contact) {
    errors.agree_contact = "* Required";
  }

  if (!formValues.full_name) {
    // on run if the user did not enter a title
    errors.full_name = "* You must enter your full name";
  }

  if (formValues.full_name) {
    let s = allLetter(formValues.full_name);
    if (!s) {
      errors.full_name = "* Please include only letters.";
    }
  }

  if (!formValues.company_name) {
    // on run if the user did not enter a title
    errors.company_name = "* You must enter a company name";
  }

  if (!formValues.phone) {
    // on run if the user did not enter a title
    errors.phone = "* You must enter a contact number";
  } else if (formValues.phone.length !== 10) {
    errors.phone = "* Please enter a valid phone number";
  }

  if (!formValues.email) {
    // on run if the user did not enter a title
    errors.email = "* You must enter an email";
  }

  if (!formValues.email) {
    errors.email = "* You must enter an email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
      formValues.email
    )
  ) {
    errors.email = "* Invalid email address";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "formService",
  validate: validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(FormContact);

export default connect(null, { sendMail })(formWrapped);
