import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import FormReferal from "../../forms/referal/FormReferal";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

function Referal() {
  return (
    <div className="referal">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Referral | Accountability Group Pty Ltd</title>
        <meta
          name="description"
          content="Refer 5 people who join Accountability & receive a R1000 voucher."
        />
        <link rel="canonical" href={window.location} />
      </Helmet>

      <BannerHeader
        image="/assets/image/banners-optimized/referral-banner@2x.jpg"
        title="Referral"
        noOverlay={true}
      >
        <p className="white breadcrumb">
          <Link to="/">Home</Link> | <Link to="/referral">Referral</Link>
        </p>
      </BannerHeader>

      <FormReferal />
    </div>
  );
}

export default Referal;
