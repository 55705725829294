import "./FormContainer.scss";
import React from "react";

export default function FormContainer({ children, leftAligned, noPadding }) {
  const classNames = [
    "form-container", 
    leftAligned && "left-aligned",
    noPadding && "no-padding"
  ];

  return <div className={classNames.join(" ")}>{children}</div>;
}
