import { SHOW_FEEDBACK, HIDE_FEEDBACK } from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SHOW_FEEDBACK:
      const data = {
        message: action.payload.message,
        show: true
      };
      return data;
    case HIDE_FEEDBACK:
      return { ...state, show: false };

    default:
      return state;
  }
}
