import "./Modal.scss";
import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

const modal = document.getElementById("modal");

class ModalPortal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.el.classList.add("modal");
    modal.appendChild(this.el);
  }

  componentDidUpdate() {
    if (parseInt(this.props.stateID) === parseInt(this.props.propID)) {
      if (this.props.show) {
        this.el.classList.add("active");
      } else {
        this.el.classList.remove("active");
      }
    }
  }

  componentWillUnmount() {
    modal.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    show: state.modal.isActive,
    stateID: state.modal.id,
    propID: ownProps.id
  };
};

export default connect(mapStateToProps)(ModalPortal);
