import "./Banner.scss";
import React from "react";
import Container from "../container/Container";

export default function Banner({
  children,
  image,
  height,
  noOverlay,
  paralax,
  // scrollDown,
  small
}) {
  const bannerStyles = () => {
    if (image) {
      return {
        background: `url('${image}')`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        height: height,
        width: "100%",
        position: "relative",
        backgroundAttachment: paralax ? "fixed" : "scroll"
      };
    }

    return {};
  };

  const overlayStyles = [
    "banner-overlay",
    noOverlay && "no-overlay",
    small && "small"
  ];

  const renderContent = () => {
    if (image) {
      return (
        <div className={overlayStyles.join(" ")}>
          <div>
            <Container>
              <div className="banner-content">{children}</div>
            </Container>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Container>
          <div className="banner-content">{children}</div>
        </Container>
      </div>
    );
  };

  return (
    <div className="banner" style={bannerStyles()}>
      {renderContent()}
    </div>
  );
}
