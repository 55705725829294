const validate = formValues => {
  const errors = {};

  if (!formValues.personal_name) {
    // on run if the user did not enter a title
    errors.personal_name = "* You must enter your name";
  } else if(!/^[A-Za-z ]+$/i.test(
      formValues.personal_name
    )) {
    errors.personal_name = "* Only Letters Allowed";
  }

  if (!formValues.personal_contact_no) {
    // on run if the user did not enter a title
    errors.personal_contact_no = "* You must enter a contact number";
  } else if (
    !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(formValues.personal_contact_no)
  ) {
    errors.personal_contact_no = "* Please enter a valid phone number";
  } else if (formValues.personal_contact_no.length < 10){
    errors.personal_contact_no = "* Phone number is too short";
  }

  if (!formValues.personal_email) {
    // on run if the user did not enter a title
    errors.personal_email = "* You must enter an email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(formValues.personal_email)
  ) {
    errors.personal_email = "* Invalid email address";
  }

  if (!formValues.personal_help) {
    // on run if the user did not enter a title
    errors.personal_help = "* This field is required";
  }

  // REFERAL 1

  if (!formValues.referral_company1) {
    // on run if the user did not enter a title
    errors.referral_company1 = "* You must enter a Company Name";
  }

  if (!formValues.referral_contact_person1) {
    // on run if the user did not enter a title
    errors.referral_contact_person1 = "* You must enter a First & Last Name";
  } else if(!/^[A-Za-z ]+$/i.test(
      formValues.referral_contact_person1
    )) {
    errors.referral_contact_person1 = "* Only Letters Allowed";
  }

  if (!formValues.referral_contact_number1) {
    // on run if the user did not enter a title
    errors.referral_contact_number1 = "* You must enter a Contact Number";
  } else if (
    !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(formValues.referral_contact_number1)
  ) {
    errors.referral_contact_number1 = "* please enter a valid phone number";
  } else if (formValues.referral_contact_number1.length < 10){
    errors.referral_contact_number1 = "* Phone number is too short";
  }

  if (!formValues.referral_contact_email1) {
    // on run if the user did not enter a title
    errors.referral_contact_email1 = "* You must enter an Email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
      formValues.referral_contact_email1
    )
  ) {
    errors.referral_contact_email1 = "* Invalid email address";
  }

  if (!formValues.referral_contact_message1) {
    // on run if the user did not enter a title
    errors.referral_contact_message1 = "* You must enter a Message";
  }

  //REFERAL 2

  if (formValues.referral_contact_person2 && !/^[A-Za-z ]+$/i.test(formValues.referral_contact_person2)) {
    errors.referral_contact_person2 = "* Only Letters Allowed";
  } 

  if (formValues.referral_contact_number2 && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(formValues.referral_contact_number2)) {
    errors.referral_contact_number2 = "* please enter a valid phone number";
  } else if (formValues.referral_contact_number2 && formValues.referral_contact_number2.length < 10){
    errors.referral_contact_number2 = "* Phone number is too short";
  }

  if (formValues.referral_contact_email2) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
        formValues.referral_contact_email2
      )
    ) {
      errors.referral_contact_email2 = "* Invalid email address";
    }
  }

  //REFERAL 3

  if (formValues.referral_contact_person3 && !/^[A-Za-z ]+$/i.test(formValues.referral_contact_person3)) {
    errors.referral_contact_person3 = "* Only Letters Allowed";
  }

  if (formValues.referral_contact_number3 && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(formValues.referral_contact_number3)) {
    errors.referral_contact_number3 = "* please enter a valid phone number";
  } else if (formValues.referral_contact_number3 && formValues.referral_contact_number3.length < 10){
    errors.referral_contact_number3 = "* Phone number is too short";
  }

  if (formValues.referral_contact_email3) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
        formValues.referral_contact_email3
      )
    ) {
      errors.referral_contact_email3 = "* Invalid email address";
    }
  }

  //REFERAL 4

  if (formValues.referral_contact_person4 && !/^[A-Za-z ]+$/i.test(formValues.referral_contact_person4)) {
    errors.referral_contact_person4 = "* Only Letters Allowed";
  }

  if (formValues.referral_contact_number4 && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(formValues.referral_contact_number4)) {
    errors.referral_contact_number4 = "* please enter a valid phone number";
  } else if (formValues.referral_contact_number4 && formValues.referral_contact_number4.length < 10){
    errors.referral_contact_number4 = "* Phone number is too short";
  }

  if (formValues.referral_contact_email4) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
        formValues.referral_contact_email4
      )
    ) {
      errors.referral_contact_email4 = "* Invalid email address";
    }
  }

  //REFEREAL 5

  if (formValues.referral_contact_person5 && !/^[A-Za-z ]+$/i.test(formValues.referral_contact_person5)) {
    errors.referral_contact_person5 = "* Only Letters Allowed";
  }

  if (formValues.referral_contact_number5 && !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(formValues.referral_contact_number5)) {
    errors.referral_contact_number5 = "* please enter a valid phone number";
  } else if (formValues.referral_contact_number5 && formValues.referral_contact_number5.length < 10){
    errors.referral_contact_number5 = "* Phone number is too short";
  }

  if (formValues.referral_contact_email5) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(
        formValues.referral_contact_email5
      )
    ) {
      errors.referral_contact_email5 = "* Invalid email address";
    }
  }

  return errors;
};

export default validate;
