import './Section.scss'
import React from 'react'

export default function Section({ id, children, noMargin }) {
  const classNames = [
    noMargin && 'no-margin',
  ]
  return (
    <section id={id} className={classNames.join(" ")}>
      {children}
    </section>
  )
}

