import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import FormLogin from "../../forms/FormLogin";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Text from "../../ui/text/Text";
import Container from "../../ui/container/Container";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

const Login = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Log In | Accountability Group Pty Ltd</title>
        <meta
          name="description"
          content="Log in to your Accountability Membership Page."
        />
        <link rel="canonical" href={window.location} />
      </Helmet>
      <BannerHeader
        image="/assets/image/banners-optimized/log-in-banner@2x-compressor.jpg"
        title="Membership Page"
      >
        <p className="white breadcrumb">
          <Link to="/">Home</Link> | <Link to="/login">Log In</Link>
        </p>
      </BannerHeader>
      <Section>
        <SectionHeader title="Log in to Accountability" noPadding />
        <FormLogin />
      </Section>

      <Container>
        <Section>
          <SectionHeader title="Disclaimer" noPadding />
          <Text
            type="p"
            className="centered"
            text="Please note that the information that you are about to view is confidential. Should you be found guilty of distributing this information to non-Members, your contract with Accountability will be terminated. Your Membership details will be published as a defaulter and you will be prosecuted to the full extent of the law."
          />
        </Section>
      </Container>
    </React.Fragment>
  );
};

export default Login;
