import _ from 'lodash'
import { FETCH_PAGE } from '../actions/types'

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_PAGE:
      return { ...state, [action.slug]: action.payload };
    default:
      return state
  }
}
