import React from "react";
import BannerHeader from "../../ui/banner/BannerHeader";
import ReportRegister from "../../forms/3-in-1-report/ReportRegister";

const ThreeInOneReport = () => {
  return (
    <div className="services">
      <BannerHeader
        image="/assets/image/banners-optimized/comprehensive-banner@2x.jpg"
        title="Comprehensive 3-in-1 <br> Commercial Credit Report"
      />
      <ReportRegister />
    </div>
  );
};

export default ThreeInOneReport;
