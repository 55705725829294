import "./VerticalSliderAnimation.scss";
import React from "react";

function VerticalSliderAnimation({ children, className }) {
  const renderItems = () => {
    return children.map((item, index) => {
      return (
        <div key={index} className="vertical-slide-item">
          {item}
        </div>
      );
    });
  };

  return (
    <div className="vertical-slide-animation">
      <div className={`vertical-slider-container ${className}`}>
        {renderItems()}
      </div>
    </div>
  );
}

export default VerticalSliderAnimation;
