import React from "react";
import Banner from "./Banner";
import Section from "../section/Section";
import Text from "../text/Text";
import Video from "../../ui/video/VideoDetail";
import Button from "../button/Button";

function BannerAboutUs() {
  return (
    <Section id="learn-more">
      <Banner height="auto">
        <div className="banner-left half" cols="six">
          <Text
            type="h2"
            className="underlined no-shadow"
            text="Our goal is to make our Members feel secure"
          />
          <Text
            type="p"
            text="Accountability is a web-based service that was created by founder members Howard and Wendy Kemp, birthed by pure frustration, with the aim of “protecting” businesses by reducing the risk caused by outstanding payments."
          />
          <Text
            type="p"
            text="As a registered Credit Bureau, our actions are determined by the provisions of the National Credit Act, No 34 of 2005 and the National Credit Regulator."
          />

          <Button
            type="redirect"
            text="LEARN MORE ABOUT US"
            theme="green"
            url="/about-us"
            classNames={["m-r-15", "m-t-10"]}
          />
        </div>

        <div className="banner-right half" cols="six">
          <Video url="https://www.youtube.com/embed/_iCHmIPcq74" />
        </div>
      </Banner>
    </Section>
  );
}

export default BannerAboutUs;
