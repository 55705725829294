import { VERIFY_AGE } from "../actions/types";

export default (state = false, action) => {
  switch (action.type) {
    case VERIFY_AGE:
      return action.payload;

    default:
      return state;
  }
};
