import React, { Component } from "react";
import { connect } from "react-redux";
import { downloadPDF } from "../../../utils/functions/index";
import { Field, reduxForm } from "redux-form";
import {
  fetchDocumentation,
  verifyDocumentation,
  fetchMemberReference
} from "../../../actions";
import { renderCheckbox } from "../renderField";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import validate from "./validate";
import Text from "../../ui/text/Text";
import Container from "../../ui/container/Container";
import Loader from "../../ui/loader/Loader";
import { removeParam, getQueryString } from "../../../utils/functions/index";
import history from "../../../utils/history/history";

class WizardFormFourthPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    window.gtag({ event: "Register Completed" });
    let regToken = getQueryString("registration_token");
    if (regToken) {
      this.props.fetchMemberReference(regToken);
    }
  }

  handleFetchDocumentation = async () => {
    await this.props.fetchDocumentation();
    if (this.props.documentation.file_data) {
      downloadPDF(
        this.props.documentation.file_data,
        this.props.company_reference
      );
    }
  };

  onSubmit = () => {
    window.dataLayer.push({ event: "Register Completed" });
    const ip = require("ip");
    const userTokens = JSON.parse(localStorage.getItem("userTokens"));
    const data = {
      registration_token: this.props.registration_token
        ? this.props.registration_token
        : userTokens.registration_token,
      ip: ip.address()
    };
    this.props.verifyDocumentation(data);
    removeParam("page", window.location.href);
    history.push("/register");
    this.props.nextPage();
  };

  render() {
    const { handleSubmit } = this.props;
    const userTokens = JSON.parse(localStorage.getItem("userTokens"));
    const membershipNumber =
      userTokens.company_reference || this.props.company_reference;

    return (
      <Container>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Section>
            <SectionHeader title="Thank you for your submission." />
            <Text
              type="p"
              className="centered"
              text={`Your membership number is <span style="color: #20A84A;font-weight: bold">${membershipNumber}</span>`}
            />
            <Text
              type="p"
              className="centered p-b-0"
              text="To complete your registration, please download and review your documents."
            />
            <CenteredBox>
              {this.props.isLoading ? (
                <Loader />
              ) : (
                <Button
                  onClick={this.handleFetchDocumentation}
                  text="DOWNLOAD DOCUMENTATION"
                  theme="green"
                />
              )}
            </CenteredBox>

            <Text
              type="p"
              className="centered p-t-40"
              text="You are advised that, by clicking on the “SUBMIT” button, you hereby submit an electronic signature as contemplated in Section 11 of the Electronic Communications and Transactions Act, No 25 of 2002. Such signature carries with it the weight of all contractual and legal obligations which may arise from the submission of such signature."
            />

            <CenteredBox>
              <Field
                name="agree_above"
                type="checkbox"
                component={renderCheckbox}
                label="I hereby agree to the above."
                noMargin
              />
            </CenteredBox>

            <CenteredBox>
              <Button text="SUBMIT" type="submit" theme="green" />
            </CenteredBox>
          </Section>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading,
    company_reference: state.register.company_reference,
    payment_token: state.register.payment_token,
    registration_token: state.register.registration_token,
    documentation: state.documentation
  };
};

const formWrapped = reduxForm({
  form: "wizard", //Form name is same
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(WizardFormFourthPage);

export default connect(mapStateToProps, {
  fetchDocumentation,
  verifyDocumentation,
  fetchMemberReference
})(formWrapped);
