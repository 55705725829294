import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetch3in1Results,
  submit3in1Questions,
  throwError,
} from "../../../actions/index";
import { reduxForm } from "redux-form";
import validate from "./validate";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Container from "../../ui/container/Container";
import Text from "../../ui/text/Text";
import QuestionBlock from "./QuestionBlock";
import Spacer from "../../ui/structure/spacer/Spacer";
import Loader from "../../ui/loader/Loader";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class ReportRegisterSecondPage extends Component {

  errorMessage = '';

  componentDidMount() {
    console.log('componentDidMount');
    if (this.props.token) {
      this.props.fetch4in1Questions(this.props.token);
    }
  }

  renderQuestions = () => {
    if (this.errorMessage != ''){
      return;
    }
    if (!this.props.questions) {
      return <Loader />;
    }

    if (this.props.questions) {
      console.log('2');
      console.log(this.props.questions);
      const questions = this.props.questions.public_4in1_question_data.respond
        .questions;
        console.log('3');
      console.log(questions);
      const newQuestions =
        typeof questions === "string" ? JSON.parse(questions) : questions;

      if (_.isEmpty(newQuestions)) {
        this.props.throwError("Please enter a valid ID number");
      }

      return newQuestions.map(question => {

        var has_please_select = false;
        question.options.map(option => {
            if (option.optionDescription == "Please select"){
                has_please_select = true;
            }
        })
        if (has_please_select == false) {
            question.options.unshift({
                optionDescription: "Please select",
                optionId: "0",
                optionNumber: "0"
            });
        }
        return (
          <QuestionBlock
            key={question.questionNumber}
            options={question.options}
            description={question.questionDescription}
            index={question.questionNumber}
          />
        );
      });
    }
  };

  onSubmit = formValues => {
    const data = {
      ...formValues,
      public_3in1_questions_session_id: this.props.questions
      .public_4in1_question_data.respond.sessionId,
      public_3in1_token: this.props.token
    };

    this.props.submit4in1Questions(data,this);
    // const { reset } = this.props;
    // reset();
  };

  onReturnToPreviousPage = () => {
    this.props.removeQuestions();
    this.props.previousPage();
  };

  onRetryQuestions = () => {
    this.props.removeQuestions();
    this.props.fetch4in1QuestionsRetry(this.props.token,this);
  };

  renderFormContent = () => {

    if (this.errorMessage != ''){
      console.log('4');
      console.log(this.errorMessage);
      var tempmessage = this.errorMessage;
      if (this.errorMessage == 'Warning: Max retry count of 3 reached'){
          this.errorMessage = '';
           return (
            <React.Fragment>
              <CenteredBox noPadding stacked>
                <Text
                  type="p"
                  text={tempmessage}
                />
                <Button
                  onClick={this.onRetryQuestions}
                  classNames={["next", "centered", "large"]}
                  theme="green"
                  text="BACK"
                  url="/services/comprehensive-4-in-1-consumer-credit-report/submission"
                />
              </CenteredBox>
            </React.Fragment>
          );
      }else {
        console.log('5');
          console.log('no error message');
          this.errorMessage = '';
          return (
              <React.Fragment>
                  <CenteredBox noPadding stacked>
                      <Text
                          type="p"
                          text={tempmessage}
                      />
                      <Button
                          onClick={this.onRetryQuestions}
                          classNames={["next", "centered", "large"]}
                          theme="green"
                          text="RETRY"
                      />
                  </CenteredBox>
              </React.Fragment>
          );
      }
    }else
    if (this.props.questions) {
      console.log('6');
      console.log(this.props.questions);
      const questions = this.props.questions.public_4in1_question_data.respond
        .questions;
      const newQuestions =
        typeof questions === "string" ? JSON.parse(questions) : questions;

      if (_.isEmpty(newQuestions)) {
        console.log('No data available for the ID number supplied.');
        return (
          <React.Fragment>
            <CenteredBox noPadding stacked>
              <Text
                type="p"
                text="No data available for the ID number supplied."
              />
              <Button
                onClick={this.onReturnToPreviousPage}
                classNames={["next", "centered", "large"]}
                theme="green"
                text="BACK"
                url="/services/comprehensive-4-in-1-consumer-credit-report/submission"
              />
            </CenteredBox>
          </React.Fragment>
        );
      } else {
        return (
          <CenteredBox noPadding stacked>
            <Button
              type="submit"
              classNames={["next", "centered", "large"]}
              theme="green"
              text="SUBMIT"
            />
          </CenteredBox>
        );
      }
    }
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <React.Fragment>
        <Section>
          <SectionHeader noPadding autoWidth title="Authentication" />
          <Container>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              {this.renderQuestions()}
              <Spacer />
              {this.renderFormContent()}
            </form>
          </Container>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.threeInOneReport.public_3in1_token,
    questions: state.threeInOneReport.questions,
  };
};

const formWrapped = reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: true, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(ReportRegisterSecondPage);

export default connect(mapStateToProps, {
  fetch3in1Results,
  submit3in1Questions,
  throwError,
})(formWrapped);
