import React, { Component } from "react";
import FormRegisterFirstPage from "./FormRegisterFirstPage";
import FormRegisterSecondPage from "./FormRegisterSecondPage";
import FormRegisterThirdPage from "./FormRegisterThirdPage";
import FormRegisterFourthPage from "./FormRegisterFourthPage";
import FormRegisterFifthPage from "./FormRegisterFifthPage";
import FormRegisterSixthPage from "./FormRegisterSixthPage";
import { getQueryString } from "../../../utils/functions";
import { Helmet } from "react-helmet";

class FormRegister extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
      isValid: false
    };
  }

  componentDidMount() {
    const pageName = getQueryString("page");

    if (pageName) {
      const pageNames = {
        begin: 1,
        "banking-details": 2,
        "user-details": 3,
        "thank-you": 4,
        payment: 5
      };

      this.setState({ page: pageNames[pageName] });
      this.setUsertokens();
    }
  }

  setUsertokens = () => {
    if (!localStorage.getItem("userTokens")) {
      const data = {
        company_reference: getQueryString("company_reference"),
        registration_token: getQueryString("registration_token"),
        payment_token: getQueryString("payment_token")
      };

      localStorage.setItem("userTokens", JSON.stringify(data));
    }
  };

  uploadSignature = (signature, number) => {
    if (number === 1) {
      this.setState({ signature1: signature });
    }

    if (number === 2) {
      this.setState({ signature2: signature });
    }
  };

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  changeInstitution = branchCode => {
    this.setState({ branchCode });
  };

  render() {
    // const { onSubmit } = this.props
    const { page } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Accountability | Register</title>
          <meta
            name="description"
            content="Begin your registration to Accountability. Should you require an appointment with an Accountability representative, please contact us on 0861 90 90 90."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>
        {page === 1 && (
          <FormRegisterFirstPage
            onSubmit={this.nextPage}
            nextPage={this.nextPage}
          />
        )}

        {page === 2 && (
          <FormRegisterSecondPage
            previousPage={this.previousPage}
            nextPage={this.nextPage}
            onSubmit={this.nextPage}
            changeInstitution={this.changeInstitution}
            branchCode={this.state.branchCode}
            isValid={this.state.isValid}
          />
        )}

        {page === 3 && (
          <FormRegisterThirdPage
            uploadSignature={this.uploadSignature}
            previousPage={this.previousPage}
            nextPage={this.nextPage}
            onSubmit={this.nextPage}
          />
        )}

        {page === 4 && (
          <FormRegisterFourthPage
            previousPage={this.previousPage}
            nextPage={this.nextPage}
            onSubmit={this.nextPage}
          />
        )}

        {page === 5 && (
          <FormRegisterFifthPage
            previousPage={this.previousPage}
            nextPage={this.nextPage}
            onSubmit={this.nextPage}
            // onSubmit={this.onSubmit}
          />
        )}

        {page === 6 && (
          <FormRegisterSixthPage
            previousPage={this.previousPage}
            onSubmit={this.onSubmit}
          />
        )}
      </React.Fragment>
    );
  }
}

export default FormRegister;
