
import React from 'react'

export default function NavbarHeader({ children }) {
  return (
    <div className="navbar-header">
      {children}
    </div>
  )
}
