import "./Header.scss";
import "../../../assets/scss/hamburger.scss";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ContactBar from "../contact-bar/ContactBar";
import Logo from "../logo/Logo";
import Container from "../container/Container";
import Navbar from "../navbar/Navbar";
import NavbarHeader from "../navbar/NavbarHeader";
import NavList from "../navbar/NavList";
import NavItem from "../navbar/NavItem";
import Button from "../button/Button";
import classnames from "classnames";

class Header extends Component {
  constructor(props) {
    super(props);
    this.navbarList = React.createRef();

    this.state = {
      isActive: false,
      prevScrollpos: window.pageYOffset,
      visible: true
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    this.setState({ isActive: false });
    const currentScrollPos = window.pageYOffset;

    var visible = true;
    if (currentScrollPos > 200) {
      visible = false;
    }

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };

  toggleMenu = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    const style = this.state.isActive ? "is-active" : "closed";
    return (
      <div
        className={classnames("header", {
          "header-container-hidden": !this.state.visible
        })}
      >
        <ContactBar />
        <Navbar toggleMenu={this.toggleMenu} isActive={style}>
          <Container>
            <NavbarHeader>
              <Logo />

              <div
                onClick={this.toggleMenu}
                className={`hamburger hamby hamburger--elastic ${style}`}
                id="hamby"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </div>
            </NavbarHeader>
          </Container>

          <NavList>
            <NavItem closeMenu={this.toggleMenu}>
              <NavLink className="nav-item-link" to="/">
                HOME
              </NavLink>
            </NavItem>

            <NavItem closeMenu={this.toggleMenu}>
              <NavLink className="nav-item-link" to="/services">
                SERVICES
              </NavLink>
            </NavItem>

            <NavItem closeMenu={this.toggleMenu}>
              <NavLink className="nav-item-link" to="/about-us">
                ABOUT US
              </NavLink>
            </NavItem>

            <NavItem closeMenu={this.toggleMenu}>
              <NavLink className="nav-item-link" to="/news">
                NEWS
              </NavLink>
            </NavItem>

            {/*<NavItem closeMenu={this.toggleMenu}>
              <NavLink className="nav-item-link" to="/social-feed">
                SOCIAL FEED
              </NavLink>
            </NavItem>*/}

            <NavItem closeMenu={this.toggleMenu}>
              <NavLink className="nav-item-link" to="/contact-us">
                CONTACT US
              </NavLink>
            </NavItem>

            <NavItem closeMenu={this.toggleMenu}>
              {/* <Button
                type="redirect"
                text="4-in-1 REPORT"
                theme="yellow"
                url="/services/comprehensive-4-in-1-consumer-credit-report"
              ></Button> */}

              <div className="button yellow three-in-one-btn"><a href="/services/comprehensive-3-in-1-commercial-credit-report/register"><div className="buttonSlide"></div><button>3-in-1 REPORT</button></a></div>

              <div className="button yellow"><a href="/services/comprehensive-4-in-1-consumer-credit-report/register"><div className="buttonSlide"></div><button>4-in-1 REPORT</button></a></div>

              <Button
                type="redirect"
                text="LOG IN"
                theme="green"
                url="/login"
              ></Button>
              <Button
                type="redirect"
                text="REGISTER"
                theme="green"
                url="/register"
              ></Button>
            </NavItem>
          </NavList>
        </Navbar>
      </div>
    );
  }
}

export default Header;
