import React from "react";
import Container from "../ui/container/Container";
import Text from "../ui/text/Text";
import Button from "../ui/button/Button";
import HideOnMobile from "../ui/structure/hide-on-mobile/HideOnMobile";

export default function Success() {
  return (
    <React.Fragment>
      <Container>
        <div
          className="form-success"
          style={{
            height: "600px",
            position: "relative",
            display: "flex",
            justifyContent: "left",
            alignItems: "center"
          }}
        >
          <div className="thank-you-content">
            <Text type="h2" text="Thank you." />
            <Text
              type="p"
              text="A Client Care consultant will be in contact with you shortly."
            />
            <Button type="redirect" theme="green" text="BACK TO HOME" url="/" />
          </div>

          <HideOnMobile>
            <div
              className="thank-you-image"
              style={{ position: "absolute", bottom: 0, right: 0 }}
            >
              <img
                src="/assets/image/people/Lady.png"
                alt=""
                style={{ maxWidth: "340px" }}
              />
            </div>
          </HideOnMobile>
        </div>
      </Container>
    </React.Fragment>
  );
}
