import React from "react";
import FormContact from "../../forms/FormContact";
import BannerHeader from "../../ui/banner/BannerHeader";
import BannerRegister from "../../ui/banner/BannerRegister";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import Container from "../../ui/container/Container";
import GoogleMap from "../../ui/maps/GoogleMap";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

const ContactUs = () => {
  return (
    <div className="contact-us">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Accountability Group Pty Ltd</title>
        <meta
          name="description"
          content="Get in touch with Accountability. Send us a message and let us know how we can be of assistance."
        />
        <link rel="canonical" href={window.location} />
      </Helmet>
      <BannerHeader
        image="/assets/image/banners-optimized/contact-us-banner@2x-compressor.jpg"
        title="Contact Us"
        // breadcrumb="Home | Contact Us"
      >
        <p className="white breadcrumb">
          <Link to="/">Home</Link> | <Link to="/contact-us">Contact Us</Link>
        </p>
      </BannerHeader>
      <Container>
        <Section>
          <SectionHeader
            title="Get In Touch with Accountability"
            subtitle="Send us a message and let us know how we can be of assistance."
          />
          <Grid>
            <GridItem
              classNames={["col-lg-7", "col-md-12", "col-sm-12", "m-b-25"]}
            >
              <FormContact />
            </GridItem>

            <GridItem
              classNames={["col-lg-5", "col-md-12", "col-sm-12", "m-b-25"]}
            >
              <GoogleMap />
            </GridItem>
          </Grid>
        </Section>
      </Container>

      <BannerRegister />
    </div>
  );
};

export default ContactUs;
