import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchNewsArticles } from "../../../actions/index";
import { apiImagePath, chunk } from "../../../utils/functions";
import BannerHeader from "../../ui/banner/BannerHeader";
import BannerRegister from "../../ui/banner/BannerRegister";
import Section from "../../ui/section/Section";
import Grid from "../../ui/grid/Grid";
import GridItem from "../../ui/grid/GridItem";
import PreviewCard from "../../ui/card/preview-card/PreviewCard";
import Container from "../../ui/container/Container";
import Loader from "../../ui/loader/Loader";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

class News extends Component {
  componentDidMount() {
    this.props.fetchNewsArticles();
  }

  renderArticleChunks = () => {
    if (_.isEmpty(this.props.articles)) {
      return <Loader />;
    }

    let articleChunks = chunk(this.props.articles, 4);
    return articleChunks.map((chunk, index) => {
      return <Grid key={index}>{this.renderArticles(chunk)}</Grid>;
    });
  };

  renderArticles = chunk => {
    return chunk.map(article => {
      const articleUrl = `/news/${article.slug}`;
      return (
        <GridItem key={article.id} classNames={["col-lg-3", "col-sm-6"]}>
          <PreviewCard
            truncated
            image={apiImagePath(article.preview_image)}
            title={article.preview_title}
            text={article.preview_content}
            url={articleUrl}
          />
        </GridItem>
      );
    });
  };

  renderPageContent() {
    return (
      <React.Fragment>
        <BannerHeader
          image="/assets/image/banners-optimized/news-banner@2x-compressor.jpg"
          title="Industry News"
        >
          <p className="white breadcrumb">
            <Link to="/">Home</Link> | <Link to="/news">Industry News</Link>
          </p>
        </BannerHeader>

        <Section id="section-news">
          <Container>{this.renderArticleChunks()}</Container>
        </Section>

        <BannerRegister />
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="news">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Industry News | Accountability Group Pty Ltd</title>
          <meta
            name="description"
            content="Stay up to date with the latest Industry News from Accountability."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        {this.renderPageContent()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    articles: Object.values(state.news)
  };
};

export default connect(
  mapStateToProps,
  { fetchNewsArticles }
)(News);
