import React from "react";
import Banner from "./Banner";
import Grid from "../grid/Grid";
import GridItem from "../grid/GridItem";
import Section from "../section/Section";
import StatCard from "../card/stat-card/StatCard";
import Container from "../container/Container";

function BannerStats() {
  const renderBanner = () => {
    return (
      <Banner
        paralax
        image="/assets/image/01-Home-Page/paralax/est-since-background.jpg"
        height="auto"
      >
        <div className="banner-full" cols="six">
          <Grid>
            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="regular"
                title="Est. Since <br> 2005"
                text="Founding Members: <br> Howard and Wendy Kemp"
              />
            </GridItem>

            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="large"
                title="25m"
                text="Credit Active <br> Consumers"
              />
            </GridItem>

            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="large"
                title="5.2m"
                text="Credit Active <br> Businesses"
              />
            </GridItem>

            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="large"
                title="61"
                text="Available <br> Products"
              />
            </GridItem>
          </Grid>
        </div>
      </Banner>
    );
  };

  const renderBannerIpad = () => {
    return (
      <div
        style={{
          padding: "60px 0",
          background:
            'url("/assets/image/banners-optimized/since-tablet-background.jpg")',
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
        <Container>
          <Grid>
            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="regular"
                title="Est. Since <br> 2005"
                text="Founding Members: <br> Howard and Wendy Kemp"
              />
            </GridItem>

            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="large"
                title="25m"
                text="Credit Active <br> Consumers"
              />
            </GridItem>

            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="large"
                title="5.2m"
                text="Business Credit <br> Database Access"
              />
            </GridItem>

            <GridItem classNames={["col-lg-3", "col-md-3", "col-sm-6"]}>
              <StatCard
                size="large"
                title="61"
                text="Available <br> Products"
              />
            </GridItem>
          </Grid>
        </Container>
      </div>
    );
  };

  return (
    <Section id="stats">
      {window.innerWidth < 770 ? renderBannerIpad() : renderBanner()}
    </Section>
  );
}

export default BannerStats;
