import "./topFeedback.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hideFeedback } from "../../../actions/utils/Feedback";
import {
  faExclamationCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

class TopFeedback extends Component {
  renderMessage = msg => {
    if (!this.props.message) {
      return null;
    }

    return this.props.message.message;
  };

  render() {
    return (
      <div className="top-feedback-container">
        <div className="content">
          <FontAwesomeIcon icon={faExclamationCircle} />
          <div className="message">{this.renderMessage()}</div>
        </div>
        <div className="control-close">
          <FontAwesomeIcon onClick={this.props.hideFeedback} icon={faTimes} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.feedback.show,
    message: state.feedback
  };
};

export default connect(
  mapStateToProps,
  { hideFeedback }
)(TopFeedback);
