import React from "react";
import Banner from "./Banner";
import Button from "../button/Button";
import ButtonList from "../button/ButtonList";
import Section from "../section/Section";
import Text from "../text/Text";
import Container from "../container/Container";
import Grid from "../grid/Grid";
import GridItem from "../grid/GridItem";

function BannerPromo() {
  const renderBanner = () => {
    return (
      <Banner
        image="/assets/image/09-Referral/referral-background-banner@2x.jpg"
        height="auto"
        noOverlay
        small
      >
        <div className="banner-left half " cols="six">
          <img
            className="banner-image-small"
            src="/assets/image/01-Home-Page/Voucher-Card@2x.png"
            alt=""
          />
        </div>

        <div className="banner-right half center-mobile" cols="six">
          <Text
            type="h2"
            className="white"
            text="Refer 5 people who join Accountability & receive a R1000 voucher."
          />

          <Button
            type="redirect"
            text="REFERRAL"
            theme="purple"
            url="/referral"
            classNames={["m-r-15", "m-t-10"]}
          />
        </div>
      </Banner>
    );
  };

  const renderBannerIpad = () => {
    return (
      <div
        style={{
          padding: "60px 0",
          background:
            'url("/assets/image/09-Referral/referral-background-banner@2x.jpg")',
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
        <Container>
          <Grid>
            <GridItem classNames={["col-md-12"]}>
              <img
                style={{ width: "100%", maxWidth: "350px", margin: "0 auto" }}
                className="banner-image-small"
                src="/assets/image/01-Home-Page/Voucher-Card@2x.png"
                alt=""
              />
            </GridItem>

            <GridItem classNames={["col-md-12"]}>
              <Text
                type="h2"
                className="white centered"
                text="Refer 5 people who join Accountability & receive a R1000 voucher."
              />

              <ButtonList centered>
                <Button
                  type="redirect"
                  text="REFERRAL"
                  theme="purple"
                  url="/referral"
                  classNames={["m-r-15", "m-t-10"]}
                />
              </ButtonList>
            </GridItem>
          </Grid>
        </Container>
      </div>
    );
  };
  return (
    <Section id="refer">
      <Container>
        {window.innerWidth < 770 ? renderBannerIpad() : renderBanner()}
      </Container>
    </Section>
  );
}

export default BannerPromo;
