import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";
import { fetch3In1Token } from "../../../actions/index";
import {
  renderInput,
  renderInputWithLabel,
  renderSelect,
  renderCheckbox
} from "../renderField";
import Button from "../../ui/button/Button";
import CenteredBox from "../../ui/structure/CenteredBox";
import Section from "../../ui/section/Section";
import SectionHeader from "../../ui/section/SectionHeader";
import Container from "../../ui/container/Container";
import FormContainer from "../../ui/container/form-container/FormContainer";
import Text from "../../ui/text/Text";
import Recaptcha from "react-recaptcha";
import Spacer from "../../ui/structure/spacer/Spacer";
import Loader from "../../ui/loader/Loader";
import scrollToFirstError from '../SmoothScrollToErrorFields.js';

class ReportRegisterFirstPage extends Component {

  constructor(props){
    super(props)
    this.verifyCallbackRecapcha = this.verifyCallbackRecapcha.bind(this);
    this.onloadCallbackRecapcha = this.onloadCallbackRecapcha.bind(this);
    this.state = {
      recapchaIsVerified: false
    }
  }  

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onloadCallbackRecapcha(){
    console.log('recapcha has loaded');
  }

  verifyCallbackRecapcha(response){
    if(response){
      this.setState({
        recapchaIsVerified: true
      }); 
    }      
    console.log('recapcha has been verified');
  }

  onSubmit = formValues => {
    if(this.state.recapchaIsVerified){
      this.props.fetch3In1Token(formValues, this.props.nextPage);
      window.scrollTo(0, 0);
    } else {
      console.log('recapcha has not been verified');
      alert('Please verify that you are human!');
    }
  };

  render() {
    const { handleSubmit } = this.props;
    const typeOptions = ["Please Select", "Query by Name", "Query by Registration"];
    const reasonOptions = ["Please Select", "Credit Apllication", "Credit Limit Management", "Insurance Apllication", "Insurance Claim", "Employment and/or Educational Check", "Fraud Corruption or Theft Investigation", "Fraud Detection and Fraud Prevention", "Continuous Service Provision Limit", "Unclaimed Funds Distribution", "Affordability / Financial Means Assessment", "Propensity to Repay"];

    if (this.props.isLoading) {
      return <Loader halfscreen />;
    }

    return (
      <React.Fragment>
        <Container>
          <Section id="section-register">
            <SectionHeader noPadding autoWidth title="Registration" />
            <Text
              type="p"
              className="centered"
              text="The Comprehensive 3-in-1 Commercial Credit Report offers a thorough assessment of a business's creditworthiness by combining data from Inoxico, TransUnion, and XDS. This enables companies to gain an in-depth understanding of the financial and operational aspects of a potential partner or client, enabling informed decision-making and risk assessment."
            />
            <Text
              type="p"
              className="centered"
              text="The cost per report is <span class='color-green bold'>________</span> (incl. VAT)."
            />
          </Section>
        </Container>

        <Section>
          <SectionHeader noPadding autoWidth title="Search Details" />

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <FormContainer>
              {'public_3in1_registration' && <div name={`position-public_3in1_registration`} />}
              <Field
                name="public_3in1_registration"
                type="text"
                component={renderInputWithLabel}
                label="Business Name OR Registration Number *"
                note="Please include the relevant slashes. (/) when entering the registration number. Eg. 2008/012163/07"
              />

              {'public_3in1_query_type' && <div name={`position-public_3in1_query_type`} />}
              <Field
                name="public_3in1_query_type"
                type="text"
                component={renderSelect}
                label="Enquiry Type *"
                options={typeOptions}
              />
              {'public_3in1_reason' && <div name={`position-public_3in1_reason`} />}
              <Field
                name="public_3in1_reason"
                type="text"
                component={renderSelect}
                label="Reason for Enquiry *"
                options={reasonOptions}
              />
              {'public_3in1_name' && <div name={`position-public_3in1_name`} />}
              <Field
                name="public_3in1_name"
                type="text"
                component={renderInputWithLabel}
                label="Name and Surname of Requester *"
              />
              {'public_3in1_email' && <div name={`position-public_3in1_email`} />}
              <Field
                name="public_3in1_email"
                type="email"
                component={renderInputWithLabel}
                label="Email Address Of Requester *"
                note="Please enter the email address where the report will be sent."
              />
              {'email_address_confirmation' && <div name={`position-email_address_confirmation`} />}
              <Field
                name="email_address_confirmation"
                type="email"
                component={renderInputWithLabel}
                label="Confirm Email Address *"
              />
            </FormContainer>

            <Container>
              <Spacer size="120" />
              <article>
                <Text type="h3" className="default" text="Please Note:" />
                <Text
                  type="p"
                  text="You will be charged R___.__ (incl. VAT) for a successful enquiry."
                />
                <Text
                  type="p"
                  text="The fields marked with a * are mandatory and you will not be able to
                  perform the search without them."
                />
                <Text
                  type="h3"
                  className="default"
                  text="Terms of Regulation:"
                />
                <Text
                  type="p"
                  text="This report contains information from public records that Accountability Group (Pty) Ltd does not have control of and has not been verified unless otherwise indicated in the issued report. The reports are subject to Accountability’s Terms and Conditions available <a style='text-decoration: underline' href='/terms-and-conditions' target='_blank'>here.</a>"
                />
                <Text
                  type="p"
                  text="By submitting this request, you also confirm and warrant that;"
                />
                <ul className="mission-ul">
                  <li style={{display: 'flex'}}>
                    <p style={{marginRight: '5px'}}>(a)</p> <p>you comply with the requirements set out in the Protection of Personal Information Act, 4 of 2013 and its Regulations;</p>
                  </li>
                  <li style={{display: 'flex'}}>
                    <p style={{marginRight: '5px'}}>(b)</p> <p> you have obtained the relevant consent from your clients for the collection, collation, processing, and storing of such information by Accountability; and</p>
                  </li>
                  <li style={{display: 'flex'}}>
                    <p style={{marginRight: '5px'}}>(c)</p> <p> that your clients are aware that the purpose of the processing will relate to the services Accountability render and may also include placing such information on the relevant Credit Bureaus which would, in eKect, be available in the public domain.</p>
                  </li>
                </ul>
                <Text
                  type="p"
                  text="Although not specifically required by the provisions of Regulation 18(5) of the National Credit Regulations, which requires the written consent of a consumer to be obtained before a credit report on that person may be drawn, it remains advisable for you to, in writing, pre-warn clients that are juristic persons that a Commercial Credit Report will be performed on their company. Such pre-warning may be incorporated into your standard Terms and Conditions of trade."
                />
                {'agree_terms' && <div name={`position-agree_terms`} />}
                <Field
                  name="agree_terms"
                  type="checkbox"
                  component={renderCheckbox}
                  label="<span style='font-family: montserratregular; font-size: 16px; letter-spacing: 0.3px;'>I agree to the Terms of Regulation as stated above *</span>"
                  noMargin
                />
              </article>

              <Spacer size="60" />
              <CenteredBox noPadding stacked>
                <Recaptcha 
                  sitekey="6LdrRuIpAAAAAIs5gWmO9MMDIIWI2N_ibzNqhPC_"
                  render="explicit"
                  onloadCallback={this.onloadCallbackRecapcha}
                  verifyCallback={this.verifyCallbackRecapcha}
                />
                <Button
                  type="submit"
                  classNames={["next", "centered", "large"]}
                  theme="green"
                  text="NEXT"
                >
                  Next
                </Button>
              </CenteredBox>
            </Container>
          </form>
        </Section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.isLoading
  };
};

const formWrapped = reduxForm({
  form: "wizard", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(ReportRegisterFirstPage);

export default connect(mapStateToProps, { fetch3In1Token })(formWrapped);
