import "./Testimonial.scss";
import React from "react";
import Text from "../text/Text";

export default function Testimonial({ text }) {
  const styles = {
    fontSize: "14px",
    height: "400px",
    paddingRight: window.innerWidth < 768 && text.length > 400 ? "20px" : "0",
    overflowY: window.innerWidth < 768 && text.length > 400 ? "scroll" : "auto"
  };

  return (
    <div className="testimonial" style={styles}>
      <div className="quote">
        <img src="/assets/image/01-Home-Page/testimonials-icon@2x.png" alt="" />
      </div>
      <Text
        type="div"
        className={window.innerWidth > 768 ? "size-medium" : ""}
        text={text}
      />
    </div>
  );
}
