import "./Logo.scss";
import React from "react";
import { Link } from 'react-router-dom';

export default function Logo({ type, position }) {
  const classNames = ["logo", position && position];

  const switchDarkLight = () => {
    if (type === "dark") {
      return "../../../../assets/image/accountability-logo.svg";
    }

    if (type === "light") {
      return "../../../../assets/image/accountability-logo-white.svg";
    }

    return "../../../../assets/image/accountability-logo.svg";
    // return "/assets/image/accountability-logo-xmas@2x.png";
  };
  return (
    <div className={classNames.join(" ")}>
      <Link to="/"><img src={switchDarkLight()} alt="" /></Link>
    </div>
  );
}
