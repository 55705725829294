import './bootstrap.css'
import React from 'react'

export default function GridItem({ classNames, children }) {
  const renderClassNames = () => {
    if(classNames){
      return classNames.join(' ')
    }
  }

  return (
    <div className={`col ${renderClassNames()}`}>
      {children}
    </div>
  )
}
