import React, { Component } from "react";
import ReportRegisterFirstPage from "./ReportRegisterFirstPage";
import ReportRegisterSecondPage from "./ReportRegisterSecondPage";
import ReportRegisterThirdPage from "./ReportRegisterThirdPage";
import { Helmet } from "react-helmet";

class ReportRegister extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
      isValid: false
    };
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { page } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Accountability | Comprehensive 4-in-1 Consumer Credit Report |
            Register
          </title>
          <meta
            name="description"
            content="The Comprehensive 4-in-1 Consumer Credit Report combines all your credit information retained by the four major South African based Credit Bureaus namely TransUnion, Experian, XDS and VeriCred."
          />
          <link rel="canonical" href={window.location} />
        </Helmet>

        {page === 1 && (
          <ReportRegisterFirstPage
            nextPage={this.nextPage}
            onSubmit={this.fetch4in1Token}
          />
        )}
        {page === 2 && (
          <ReportRegisterSecondPage
            previousPage={this.previousPage}
            nextPage={this.nextPage}
          />
        )}
        {page === 3 && <ReportRegisterThirdPage nextPage={this.nextPage} />}
      </React.Fragment>
    );
  }
}

// const mapStateToprops = (state) => {
//   return {
//     token: this.state.fourInOneReport.public_4in1_token,
//   }
// }

export default ReportRegister;
