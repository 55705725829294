import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchNewsArticle } from "../../../actions/index";
import BannerHeader from "../../ui/banner/BannerHeader";
import NewsHeader from "./NewsHeader";
import Section from "../../ui/section/Section";
import Container from "../../ui/container/Container";
import { ShareButtonIconOnly } from "react-custom-share";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import IconList from "../../ui/icon/icon-list/IconList";
import Loader from "../../ui/loader/Loader";
import ReactHtmlParser from "react-html-parser";
import { apiImagePath } from "../../../utils/functions/index";
import { Helmet } from "react-helmet";
import {Link} from 'react-router-dom'

class NewsDetail extends Component {
  componentDidMount() {
    this.props.fetchNewsArticle(this.props.match.params.slug);
  }

  renderArticle = () => {

    if(this.props.article === null){
      window.location.href = '/news'
    }

    if (!this.props.article) {
      return <Loader />;
    }

    const {
      main_title,
      main_content,
      main_image,
      meta_description,
      author,
      preview_content,
      created_at
    } = this.props.article;

    const shareUrl = window.location.href;

    const FacebookProps = {
      url: shareUrl,
      network: "Facebook",
      text: main_title,
      longtext: preview_content
    };

    const TwitterProps = {
      url: shareUrl,
      network: "Twitter",
      text: main_title,
      longtext: preview_content
    };

    const LinkedinProps = {
      url: shareUrl,
      network: "Linkedin",
      text: main_title,
      longtext: preview_content
    };

    const breadCrumb = `Home | Industry News | ${main_title}`;

    const ldJson = {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": window.location.href
      },
      "headline": main_title,
      "description": meta_description,
      "image": apiImagePath(main_image),  
      "author": {
        "@type": "Organization",
        "name": "Accountability"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Optimal Online",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.optimalonline.co.za/images/optimal-online-logo.png"
        }
      },
      "datePublished": created_at.split(' ')[0]
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{main_title} | Accountability Group Pty Ltd</title>
          <meta name="description" content={meta_description} />
          <link rel="canonical" href={window.location} />
          <script type="application/ld+json">
              {JSON.stringify(ldJson)}
          </script>
        </Helmet>

        <BannerHeader
          image="/assets/image/banners-optimized/news-banner@2x-compressor.jpg"
          title="Industry News"
        >
          <p className="white breadcrumb">
            <Link to="/">Home</Link> | <Link to="/news">Industry News</Link> | {main_title}
          </p>
        </BannerHeader>

        <Container>
          <Section>
            <img src={apiImagePath(main_image)} alt={main_title} />
            <NewsHeader
              title={main_title}
              date={`${created_at}`}
              author={author}
            />

            <article>{ReactHtmlParser(main_content)}</article>
          </Section>
          <hr />

          <IconList>
            <span className="bold">Share: </span>
            <ShareButtonIconOnly {...FacebookProps}>
              <FaFacebookF />
            </ShareButtonIconOnly>
            <ShareButtonIconOnly {...TwitterProps}>
              <img
            src="/assets/image/icons/theme/twitter-icon.svg"
            alt="twitter logo"
          />
              {/*<FaTwitter />*/}
            </ShareButtonIconOnly>
            <ShareButtonIconOnly {...LinkedinProps}>
              <FaLinkedin />
            </ShareButtonIconOnly>
          </IconList>
        </Container>
      </React.Fragment>
    );
  };

  render() {
    return this.renderArticle();
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    article: state.news[ownProps.match.params.slug]
  };
};

export default connect(
  mapStateToProps,
  { fetchNewsArticle }
)(NewsDetail);
