import "./News.scss";
import React from "react";
import Text from "../../ui/text/Text";

const NewsHeader = ({ title, date, author }) => {
  return (
    <div className="news-header">
      <Text type="h2" className="m-t-60 p-b-15" text={title} />
      <div className="article-details">
        <div className="article-detail">
          <span style={{ fontFamily: "montserratbold" }}>By: </span>{" "}
          <span style={{ marginRight: "20px" }}>{author}</span>
        </div>
        <div className="article-detail">
          <span style={{ fontFamily: "montserratbold" }}>Date: </span>{" "}
          <span>{date}</span>
        </div>
      </div>

      <hr />
    </div>
  );
};

export default NewsHeader;
