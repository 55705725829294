import "./GoogleMap.scss";
import React from "react";

function GoogleMap() {
  return (
    <div className="map-responsive">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.9904719823444!2d18.639450516390532!3d-33.86413642625069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc50b5fc23cfe3%3A0x9f6d1c07e3c7f9c1!2sAccountability!5e0!3m2!1sen!2sza!4v1569410609786!5m2!1sen!2sza"
        title="google map"
        frameBorder="0"
        style={{ border: "none" }}
        allowFullScreen=""
      ></iframe>
    </div>
  );
}

export default GoogleMap;
