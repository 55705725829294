import "./topFeedback.scss";
import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
const topFeedback = document.getElementById("topFeedback");
class TopFeedbackPortal extends React.Component {
  constructor(props) {
    super(props);

    this.el = document.createElement("div");
    this.el.classList.add("top-feedback");
    topFeedback.appendChild(this.el);
  }

  componentDidUpdate() {
    if(this.props.show){
      this.el.classList.add("active")
      setTimeout(() => {
        this.el.classList.remove("active")
      }, 5000);
    } else {
      this.el.classList.remove("active")
    }
  }

  componentWillUnmount() {
    topFeedback.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,

      this.el
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.feedback.show
  };
};

export default connect(mapStateToProps)(TopFeedbackPortal);
